import {SetupOptionModel} from '../option/setup-option.model';
import {SelectOption} from '../../../select/option/option.model';

import {ATTRIBUTE, DEFAULT_KEY, OPTIONS} from './constants';

export class ThumbDrawerVisibilityBehaviorModel extends SetupOptionModel {
  public selectedOption: SelectOption = null;

  public block: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, { block }): void {
    if (!this.isEnabled) {
      return;
    }

    this.block = block;

    const value: string = this.block.getAttribute(ATTRIBUTE) || DEFAULT_KEY;

    this.selectedOption = OPTIONS.find(o => o.value === value);
    this.value = this.selectedOption.value;
  }

  public setValue(key: string): void {
    if (!this.isEnabled) {
      return;
    }

    this.selectedOption = OPTIONS.find(o => o.value === key);
    this.value = this.selectedOption.value;

    this.block.setAttribute(ATTRIBUTE, this.selectedOption.value);
  }

  public reset(): void {
    this.selectedOption = OPTIONS.find(o => o.value === DEFAULT_KEY);
    this.value = this.selectedOption.value;

    this.block.setAttribute(ATTRIBUTE, DEFAULT_KEY);
  }
}
