import {SelectOption} from '../../../select/option/option.model';

export const ATTRIBUTE = 'data-thumb-drawer-visibility-behavior';

export const KEYS = {
  SHOW_HIDE: 'SHOW_HIDE',
  SHOW: 'SHOW',
};

export const DEFAULT_KEY = KEYS.SHOW_HIDE;

export const OPTIONS: SelectOption[] = [
  new SelectOption('Show & Hide', KEYS.SHOW_HIDE),
  new SelectOption('Show', KEYS.SHOW),
];
