import {IStyleOption} from './option/i-style-option';
import {StyleOptionModel} from '../settings/option/style-option.model';
import {ImageFitIntoModel} from './image-fit-into/image-fit-into.model';
import {ImageEnlargementModel} from './image-enlargement/image-enlargement.model';
import {AnimationDelayModel} from './animation-delay/animation-delay.model';
import {LoaderTypeModel} from './loader-type/loader-type.model';
import {CustomizableLayersModel} from './customizable-layers/customizable-layers.model';
import {IsItemsPaddingCustomizableModel} from './is-items-padding-customizable/is-items-padding-customizable.model';
import {IsCaptionEnabledModel} from './is-caption-enabled/is-caption-enabled.model';
import {IsBlockCenteredModel} from './is-block-centered/is-block-centered.model';
import {IsFullscreenIconShownModel} from './is-fullscreen-icon-shown/is-fullscreen-icon-shown.model';
import {IsClickToEnlargeModel} from './is-click-to-enlarge/is-click-to-enlarge.model';
import {IsFooterVisibleOnLoadModel} from './is-footer-visible-on-load/is-footer-visible-on-load.model';
import {IsPortfolioTitleAddedModel} from './is-portfolio-title-added/is-portfolio-title-added.model';
import {IsThumbDrawerArrowsVisibleModel} from './is-thumb-drawer-arrows-visible/is-thumb-drawer-arrows-visible.model';
import {IsThumbDrawerAutoscrollEnabledModel} from './is-thumb-drawer-autoscroll-enabled/is-thumb-drawer-autoscroll-enabled.model';
import {IsThumbDrawerScrollbarEnabledModel} from './is-thumb-drawer-scrollbar-enabled/is-thumb-drawer-scrollbar-enabled.model';
import {IsTitleAndYearVisibleBelowImageModel} from './is-title-and-year-visible-below-image/is-title-and-year-visible-below-image.model';
import {IsPortfolioEnlargementTitleAndYearVisibleBelowImageModel} from './is-portfolio-enlargement-title-and-year-visible-below-image/is-portfolio-enlargement-title-and-year-visible-below-image.model';
import {IsScalableTextModel} from './is-scalable-text/is-scalable-text.model';
import {IsExhibitionTitleVisibleModel} from './is-exhibition-title-visible/is-exhibition-title-visible.model';
import {IsSlideTrackerVisibleModel} from './is-slide-tracker-visible/is-slide-tracker-visible.model';
import {IsArrowsVisibleModel} from './is-arrows-visible/is-arrows-visible.model';
import {IsSlideTextVisibleModel} from './is-slide-text-visible/is-slide-text-visible.model';
import {IsSlideshowAutoplayEnabledModel} from './is-slideshow-autoplay-enabled/is-slideshow-autoplay-enabled.model';
import {InitialOverlayClickBehaviorModel} from './initial-overlay-click-behavior/initial-overlay-click-behavior.model';
import {SelectLayerModel} from './select-layer/select-layer.model';
import {BlockAlignmentModel} from './block-alignment/block-alignment.model';
import {ImageAlignmentModel} from './image-alignment/image-alignment.model';
import {SlideshowHorizontalTextAlignmentModel} from './slideshow-horizontal-text-alignment/slideshow-horizontal-text-alignment.model';
import {SlideshowVerticalTextAlignmentModel} from './slideshow-vertical-text-alignment/slideshow-vertical-text-alignment.model';
import {ImageCaptionPositionModel} from './image-caption-position/image-caption-position.model';
import {HrefModel} from './href/href.model';
import {PositionModel} from './position/position.model';
import {ImageCropModel} from './image-crop/image-crop.model';
import {ImageTransitionModel} from './image-transition/image-transition.model';
import {ImageTransitionDurationModel} from './image-transition-duration/image-transition-duration.model';
import {ImageLabelToggleModel} from './image-label-toggle/image-label-toggle.model';
import {ImageLabelButtonModel} from './image-label-button/image-label-button.model';
import {ImageLabelPositionModel} from './image-label-position/image-label-position.model';
import {LinkColorModel} from './link-color/link-color.model';
import {ButtonTextColorModel} from './button-text-color/button-text-color.model';
import {ButtonBackgroundColorModel} from './button-background-color/button-background-color.model';
import {PortfolioIdModel} from './portfolio-id/portfolio-id.model';
import {PortfolioArrowsColorModel} from './portfolio/arrows-color/portfolio-arrows-color.model';
import {PortfolioArrowsSizeModel} from './portfolio/arrows-size/portfolio-arrows-size.model';
import {PortfolioLinkModel} from './portfolio-link/portfolio-link.model';
import {PortfolioImageTitlePositionModel} from './portfolio-image-title-position/portfolio-image-title-position.model';
import {PortfolioImageTitleColorModel} from './portfolio-image-title-color/portfolio-image-title-color.model';
import {PortfolioImageTitleFontFamilyModel} from './portfolio-image-title-font-family/portfolio-image-title-font-family.model';
import {PortfolioImageTitleFontWeightModel} from './portfolio-image-title-font-weight/portfolio-image-title-font-weight.model';
import {PortfolioImageTitleFontSizeModel} from './portfolio-image-title-font-size/portfolio-image-title-font-size.model';
import {PortfolioImageTitleTextTransformModel} from './portfolio-image-title-text-transform/portfolio-image-title-text-transform.model';
import {PortfolioImageTitleTextDecorationModel} from './portfolio-image-title-text-decoration/portfolio-image-title-text-decoration.model';
import {PortfolioImageTitleLineHeightModel} from './portfolio-image-title-line-height/portfolio-image-title-line-height.model';
import {PortfolioImageTitleLetterSpacingModel} from './portfolio-image-title-letter-spacing/portfolio-image-title-letter-spacing.model';
import {PortfolioImageTitleWordSpacingModel} from './portfolio-image-title-word-spacing/portfolio-image-title-word-spacing.model';
import {PortfolioEnlargementBackgroundColorModel} from './portfolio/enlargement/background-color/portfolio-enlargement-background-color.model';
import {PortfolioEnlargementBackgroundTransparencyModel} from './portfolio/enlargement/background-transparency/portfolio-enlargement-background-transparency.model';
import {PortfolioEnlargementImageTransitionModel} from './portfolio/enlargement/image-transition/portfolio-enlargement-image-transition.model';
import {PortfolioEnlargementImageTransitionDurationModel} from './portfolio/enlargement/image-transition-duration/portfolio-enlargement-image-transition-duration.model';
import {SlideshowImageTransitionDurationModel} from './slideshow-image-transition-duration/slideshow-image-transition-duration.model';
import {PortfolioEnlargementTitlePositionModel} from './portfolio/enlargement/title/position/portfolio-enlargement-title-position.model';
import {PortfolioEnlargementTitleColorModel} from './portfolio/enlargement/title/color/portfolio-enlargement-title-color.model';
import {PortfolioEnlargementTitleFontFamilyModel} from './portfolio/enlargement/title/font-family/portfolio-enlargement-title-font-family.model';
import {PortfolioEnlargementTitleFontWeightModel} from './portfolio/enlargement/title/font-weight/portfolio-enlargement-title-font-weight.model';
import {PortfolioEnlargementTitleFontSizeModel} from './portfolio/enlargement/title/font-size/portfolio-enlargement-title-font-size.model';
import {PortfolioEnlargementTitleTextTransformModel} from './portfolio/enlargement/title/text-transform/portfolio-enlargement-title-text-transform.model';
import {PortfolioEnlargementTitleTextDecorationModel} from './portfolio/enlargement/title/text-decoration/portfolio-enlargement-title-text-decoration.model';
import {PortfolioEnlargementTitleLineHeightModel} from './portfolio/enlargement/title/line-height/portfolio-enlargement-title-line-height.model';
import {PortfolioEnlargementTitleLetterSpacingModel} from './portfolio/enlargement/title/letter-spacing/portfolio-enlargement-title-letter-spacing.model';
import {PortfolioEnlargementTitleWordSpacingModel} from './portfolio/enlargement/title/word-spacing/portfolio-enlargement-title-word-spacing.model';
import {PortfolioZoomBackgroundModel} from './portfolio-zoom-background/portfolio-zoom-background.model';
import {EnlargementIconColorModel} from './enlargement-icon-color/enlargement-icon-color.model';
import {EnlargementIconHoverColorModel} from './enlargement-icon-hover-color/enlargement-icon-hover-color.model';
import {AutoplaySlideDurationModel} from './autoplay-slide-duration/autoplay-slide-duration.model';
import {SlideshowSlideDurationModel} from './slideshow-slide-duration/slideshow-slide-duration.model';
import {PortfolioIntroBackgroundColorModel} from './portfolio-intro-background-color/portfolio-intro-background-color.model';
import {PortfolioIntroBackgroundTransparencyModel} from './portfolio-intro-background-transparency/portfolio-intro-background-transparency.model';
import {ElementBackgroundColorModel} from './element-background-color/element-background-color.model';
import {ElementBackgroundTransparencyModel} from './element-background-transparency/element-background-transparency.model';
import {PortfolioInfoIconBackgroundColorModel} from './portfolio-info-icon-background-color/portfolio-info-icon-background-color.model';
import {PortfolioInfoIconColorModel} from './portfolio-info-icon-color/portfolio-info-icon-color.model';
import {PortfolioInfoIconBorderColorModel} from './portfolio-info-icon-border-color/portfolio-info-icon-border-color.model';
import {ThumbDrawerIconSizeModel} from './thumb-drawer-icon-size/thumb-drawer-icon-size.model';
import {ThumbDrawerIconBackgroundColorModel} from './thumb-drawer-icon-background-color/thumb-drawer-icon-background-color.model';
import {ThumbDrawerIconColorModel} from './thumb-drawer-icon-color/thumb-drawer-icon-color.model';
import {ThumbDrawerIconBorderColorModel} from './thumb-drawer-icon-border-color/thumb-drawer-icon-border-color.model';
import {ThumbDrawerFontFamilyModel} from './thumb-drawer-font-family/thumb-drawer-font-family.model';
import {ThumbDrawerFontSizeModel} from './thumb-drawer-font-size/thumb-drawer-font-size.model';
import {ThumbDrawerFontWeightModel} from './thumb-drawer-font-weight/thumb-drawer-font-weight.model';
import {ThumbDrawerTextTransformModel} from './thumb-drawer-text-transform/thumb-drawer-text-transform.model';
import {ThumbDrawerLetterSpacingModel} from './thumb-drawer-letter-spacing/thumb-drawer-letter-spacing.model';
import {ThumbDrawerWordSpacingModel} from './thumb-drawer-word-spacing/thumb-drawer-word-spacing.model';
import {ThumbDrawerBackgroundColorModel} from './thumb-drawer-background-color/thumb-drawer-background-color.model';
import {ThumbDrawerBackgroundTransparencyModel} from './thumb-drawer-background-transparency/thumb-drawer-background-transparency.model';
import {ThumbDrawerTextColorModel} from './thumb-drawer-text-color/thumb-drawer-text-color.model';
import {ThumbDrawerBorderRadiusModel} from './thumb-drawer-border-radius/thumb-drawer-border-radius.model';
import {ThumbDrawerThumbsPositionModel} from './thumb-drawer-thumbs-position/thumb-drawer-thumbs-position.model';
import {ThumbDrawerVisibilityBehaviorModel} from './thumb-drawer-visibility-behavior/thumb-drawer-visibility-behavior.model';
import {PortfolioSlideThumbBackgroundColorModel} from './portfolio-slide-thumb-background-color/portfolio-slide-thumb-background-color.model';
import {PortfolioSlideThumbTextColorModel} from './portfolio-slide-thumb-text-color/portfolio-slide-thumb-text-color.model';
import {PortfolioSlideThumbFontFamilyModel} from './portfolio-slide-thumb-font-family/portfolio-slide-thumb-font-family.model';
import {PortfolioSlideThumbFontSizeModel} from './portfolio-slide-thumb-font-size/portfolio-slide-thumb-font-size.model';
import {PortfolioSlideThumbFontWeightModel} from './portfolio-slide-thumb-font-weight/portfolio-slide-thumb-font-weight.model';
import {PortfolioSlideThumbLineHeightModel} from './portfolio-slide-thumb-line-height/portfolio-slide-thumb-line-height.model';
import {PortfolioSlideThumbLetterSpacingModel} from './portfolio-slide-thumb-letter-spacing/portfolio-slide-thumb-letter-spacing.model';
import {OverlayColorModel} from './overlay-color/overlay-color.model';
import {OverlayBackgroundTransparencyModel} from './overlay-background-transparency/overlay-background-transparency.model';
import {SlideshowImageOverlayBackgroundColorModel} from './slideshow-image-overlay-background-color/slideshow-image-overlay-background-color.model';
import {SlideshowImageOverlayTransparencyModel} from './slideshow-image-overlay-transparency/slideshow-image-overlay-transparency.model';
import {ImageOverlayTextColorModel} from './image-overlay-text-color/image-overlay-text-color.model';
import {ImageOverlayBackgroundColorModel} from './image-overlay-background-color/image-overlay-background-color.model';
import {ImageOverlayBackgroundTransparencyModel} from './image-overlay-background-transparency/image-overlay-background-transparency.model';
import {ThumbOverlayBackgroundColorModel} from './thumb-overlay-background-color/thumb-overlay-background-color.model';
import {ThumbOverlayBackgroundTransparencyModel} from './thumb-overlay-background-transparency/thumb-overlay-background-transparency.model';
import {ImageTransparencyModel} from './image-transparency/image-transparency.model';
import {SpaceBetweenColumnsModel} from './space-between-columns/space-between-columns.model';
import {MasonryTypeModel} from './masonry-type/masonry-type.model';
import {GridGapModel} from './grid-gap/grid-gap.model';
import {NumberOfColumnsModel} from './number-of-columns/number-of-columns.model';
import {TitleSpacingModel} from './title-spacing/title-spacing.model';
import {ArrowsSizeModel} from './arrows-size/arrows-size.model';
import {ArrowsColorModel} from './arrows-color/arrows-color.model';
import {ArrowsBackgroundColorModel} from './arrows-background-color/arrows-background-color.model';
import {ArrowBackgroundTransparencyModel} from './arrow-background-transparency/arrow-background-transparency.model';
import {PortfolioEnlargementArrowsBorderRadiusModel} from './portfolio/enlargement/arrows/border-radius/portfolio-enlargement-arrows-border-radius.model';
import {PortfolioEnlargementArrowsColorModel} from './portfolio/enlargement/arrows/color/portfolio-enlargement-arrows-color.model';
import {PortfolioEnlargementArrowsSizeModel} from './portfolio/enlargement/arrows/size/portfolio-enlargement-arrows-size.model';
import {PortfolioEnlargementArrowsBackgroundColorModel} from './portfolio/enlargement/arrows/background-color/portfolio-enlargement-arrows-background-color.model';
import {PortfolioEnlargementArrowsBackgroundTransparencyModel} from './portfolio/enlargement/arrows/background-transparency/portfolio-enlargement-arrows-background-transparency.model';
import {BlockWidthTypeModel} from './block-width-type/block-width-type.model';
import {VideoUrlModel} from './video-url/video-url.model';
import {VideoPosterModel} from './video-poster/video-poster.model';
import {VideoControlsModel} from './video-controls/video-controls.model';
import {VideoPreloadModel} from './video-preload/video-preload.model';
import {VideoAutoplayModel} from './video-autoplay/video-autoplay.model';
import {VideoLoopModel} from './video-loop/video-loop.model';
import {VideoMutedModel} from './video-muted/video-muted.model';
import {VideoDownloadModel} from './video-download/video-download.model';
import {VideoThumbnailModel} from './video-thumbnail/video-thumbnail.model';
import {VideoEmbedModel} from './video-embed/video-embed.model';
import {VideoEmbedControlsModel} from './video-embed-controls/video-embed-controls.model';
import {VideoEmbedAutoplayModel} from './video-embed-autoplay/video-embed-autoplay.model';
import {VideoEmbedLoopModel} from './video-embed-loop/video-embed-loop.model';
import {VideoEmbedMutedModel} from './video-embed-muted/video-embed-muted.model';
import {AudioUrlModel} from './audio-url/audio-url.model';
import {AudioAutoplayModel} from './audio-autoplay/audio-autoplay.model';
import {AudioLoopModel} from './audio-loop/audio-loop.model';
import {AudioMutedModel} from './audio-muted/audio-muted.model';
import {ArrowsBorderRadiusModel} from './arrows-border-radius/arrows-border-radius.model';
import {DesktopFontSizeModel} from './desktop-font-size/desktop-font-size.model';
import {DesktopLetterSpacingModel} from './desktop-letter-spacing/desktop-letter-spacing.model';
import {DesktopLineHeightModel} from './desktop-line-height/desktop-line-height.model';
import {MobileFontSizeModel} from './mobile-font-size/mobile-font-size.model';
import {MobileLetterSpacingModel} from './mobile-letter-spacing/mobile-letter-spacing.model';
import {MobileLineHeightModel} from './mobile-line-height/mobile-line-height.model';
import {EnlargementPaddingModel} from './enlargement-padding/enlargement-padding.model';
import {PaddingVwModel} from './padding-vw/padding-vw.model';
import {HamburgerColorModel} from './hamburger-color/hamburger-color.model';
import {MobileAlbumViewSetupModel} from './mobile-album-view-setup/mobile-album-view-setup.model';
import {PortfolioZoomIconColorModel} from './portfolio-zoom-icon-color/portfolio-zoom-icon-color.model';
import {ScalableFontSizeModel} from './scalable-font-size/scalable-font-size.model';
import {ScalableLineHeightModel} from './scalable-line-height/scalable-line-height.model';
import {ScalableLetterSpacingModel} from './scalable-letter-spacing/scalable-letter-spacing.model';
import {ScalableWordSpacingModel} from './scalable-word-spacing/scalable-word-spacing.model';
import {ButtonHoverColorModel} from './button-hover-color/button-hover-color.model';
import {IsSlideButtonVisibleModel} from './is-slide-button-visible/is-slide-button-visible.model';
import {SlideshowImageBorderRadiusModel} from './slideshow-image-border-radius/slideshow-image-border-radius.model';
import {SlideshowImageBorderWidthModel} from './slideshow-image-border-width/slideshow-image-border-width.model';
import {SlideshowImageBorderStyleModel} from './slideshow-image-border-style/slideshow-image-border-style.model';
import {SlideshowImageBorderColorModel} from './slideshow-image-border-color/slideshow-image-border-color.model';
import {SlideshowTextBackgroundColorModel} from './slideshow-text-background-color/slideshow-text-background-color.model';
import {SlideshowTextBackgroundTransparencyModel} from './slideshow-text-background-transparency/slideshow-text-background-transparency.model';
import {SlideshowTitleFontFamilyModel} from './slideshow-title-font-family/slideshow-title-font-family.model';
import {SlideshowParagraphFontFamilyModel} from './slideshow-paragraph-font-family/slideshow-paragraph-font-family.model';
import {SlideshowButtonFontFamilyModel} from './slideshow-button-font-family/slideshow-button-font-family.model';
import {SlideshowTitleColorModel} from './slideshow-title-color/slideshow-title-color.model';
import {SlideshowParagraphColorModel} from './slideshow-paragraph-color/slideshow-paragraph-color.model';
import {SlideshowButtonColorModel} from './slideshow-button-color/slideshow-button-color.model';
import {SlideshowTitleFontWeightModel} from './slideshow-title-font-weight/slideshow-title-font-weight.model';
import {SlideshowParagraphFontWeightModel} from './slideshow-paragraph-font-weight/slideshow-paragraph-font-weight.model';
import {SlideshowButtonFontWeightModel} from './slideshow-button-font-weight/slideshow-button-font-weight.model';
import {SlideshowTitleTextTransformModel} from './slideshow-title-text-transform/slideshow-title-text-transform.model';
import {SlideshowParagraphTextTransformModel} from './slideshow-paragraph-text-transform/slideshow-paragraph-text-transform.model';
import {SlideshowButtonTextTransformModel} from './slideshow-button-text-transform/slideshow-button-text-transform.model';
import {SlideshowTitleTextAlignmentModel} from './slideshow-title-text-alignment/slideshow-title-text-alignment.model';
import {SlideshowParagraphTextAlignmentModel} from './slideshow-paragraph-text-alignment/slideshow-paragraph-text-alignment.model';
import {SlideshowTransitionModel} from './slideshow-transition/slideshow-transition.model';
import {ScalableSlideshowButtonFontSizeModel} from './scalable-slideshow-button-font-size/scalable-slideshow-button-font-size.model';
import {ScalableSlideshowParagraphFontSizeModel} from './scalable-slideshow-paragraph-font-size/scalable-slideshow-paragraph-font-size.model';
import {ScalableSlideshowTitleFontSizeModel} from './scalable-slideshow-title-font-size/scalable-slideshow-title-font-size.model';
import {ScalableSlideshowTitleLineHeightModel} from './scalable-slideshow-title-line-height/scalable-slideshow-title-line-height.model';
import {ScalableSlideshowParagraphLineHeightModel} from './scalable-slideshow-paragraph-line-height/scalable-slideshow-paragraph-line-height.model';
import {ScalableSlideshowButtonLineHeightModel} from './scalable-slideshow-button-line-height/scalable-slideshow-button-line-height.model';
import {ScalableSlideshowTitleLetterSpacingModel} from './scalable-slideshow-title-letter-spacing/scalable-slideshow-title-letter-spacing.model';
import {ScalableSlideshowParagraphLetterSpacingModel} from './scalable-slideshow-paragraph-letter-spacing/scalable-slideshow-paragraph-letter-spacing.model';
import {ScalableSlideshowButtonLetterSpacingModel} from './scalable-slideshow-button-letter-spacing/scalable-slideshow-button-letter-spacing.model';
import {ScalableSlideshowTitleWordSpacingModel} from './scalable-slideshow-title-word-spacing/scalable-slideshow-title-word-spacing.model';
import {ScalableSlideshowParagraphWordSpacingModel} from './scalable-slideshow-paragraph-word-spacing/scalable-slideshow-paragraph-word-spacing.model';
import {ScalableSlideshowButtonWordSpacingModel} from './scalable-slideshow-button-word-spacing/scalable-slideshow-button-word-spacing.model';
import {ButtonBgColorModel} from './button-bg-color/button-bg-color.model';
import {IsSlideshowVideoMutedModel} from './is-slideshow-video-muted/is-slideshow-video-muted.model';
import {IsSlideshowEnabledModel} from './is-slideshow-enabled/is-slideshow-enabled.model';
import {IsSlideshowAutoplayModel} from './is-slideshow-autoplay/is-slideshow-autoplay.model';

import {KEYS, TYPES} from '../../../services/styles/custom/constants';

export class SetupModel {
  public element: HTMLElement;

  public isItemsPaddingCustomizable: IsItemsPaddingCustomizableModel = new IsItemsPaddingCustomizableModel(KEYS.IS_ITEMS_MARGIN_CUSTOMIZABLE, TYPES.ATTRIBUTE);
  public isCaptionEnabled: IsCaptionEnabledModel = new IsCaptionEnabledModel(KEYS.IS_CAPTION_ENABLED, TYPES.ATTRIBUTE);
  public isBlockCentered: IsBlockCenteredModel = new IsBlockCenteredModel(KEYS.IS_BLOCK_CENTERED, TYPES.CLASS);
  public isFullscreenIconShown: IsFullscreenIconShownModel = new IsFullscreenIconShownModel(KEYS.IS_FULLSCREEN_ICON_SHOWN, TYPES.CLASS);
  public isClickToEnlarge: IsClickToEnlargeModel = new IsClickToEnlargeModel(KEYS.IS_CLICK_TO_ENLARGE, TYPES.CLASS);
  public isSlideshowEnabled: IsSlideshowEnabledModel = new IsSlideshowEnabledModel(KEYS.IS_SLIDESHOW_ENABLED, TYPES.CLASS);
  public isSlideshowAutoplay: IsSlideshowAutoplayModel = new IsSlideshowAutoplayModel(KEYS.IS_SLIDESHOW_AUTOPLAY, TYPES.CLASS);
  public isFooterVisibleOnLoad: IsFooterVisibleOnLoadModel = new IsFooterVisibleOnLoadModel(KEYS.IS_FOOTER_VISIBLE_ON_LOAD, TYPES.ATTRIBUTE);
  public isPortfolioTitleAdded: IsPortfolioTitleAddedModel = new IsPortfolioTitleAddedModel(KEYS.IS_PORTFOLIO_TITLE_VISIBLE, TYPES.ATTRIBUTE);
  public isThumbDrawerArrowsVisible: IsThumbDrawerArrowsVisibleModel = new IsThumbDrawerArrowsVisibleModel(KEYS.IS_THUMB_DRAWER_ARROWS_VISIBLE, TYPES.ATTRIBUTE);
  public isThumbDrawerAutoscrollEnabled: IsThumbDrawerAutoscrollEnabledModel = new IsThumbDrawerAutoscrollEnabledModel(KEYS.IS_THUMB_DRAWER_AUTOSCROLL_ENABLED, TYPES.ATTRIBUTE);
  public isThumbDrawerScrollbarEnabled: IsThumbDrawerScrollbarEnabledModel = new IsThumbDrawerScrollbarEnabledModel(KEYS.IS_THUMB_DRAWER_SCROLLBAR_ENABLED, TYPES.ATTRIBUTE);
  public isTitleAndYearVisibleBelowImage: IsTitleAndYearVisibleBelowImageModel = new IsTitleAndYearVisibleBelowImageModel(KEYS.IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE, TYPES.ATTRIBUTE);
  public isPortfolioEnlargementTitleAndYearVisibleBelowImage: IsPortfolioEnlargementTitleAndYearVisibleBelowImageModel = new IsPortfolioEnlargementTitleAndYearVisibleBelowImageModel(KEYS.PORTFOLIO_ENLARGEMENT_IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE, TYPES.ATTRIBUTE);
  public isScalableText: IsScalableTextModel = new IsScalableTextModel(KEYS.IS_SCALABLE_TEXT, TYPES.ATTRIBUTE);
  public isExhibitionTitleVisible: IsExhibitionTitleVisibleModel = new IsExhibitionTitleVisibleModel(KEYS.IS_EXHIBITION_TITLE_VISIBLE, TYPES.ATTRIBUTE);
  public isSlideTrackerVisible: IsSlideTrackerVisibleModel = new IsSlideTrackerVisibleModel(KEYS.IS_SLIDE_TRACKER_VISIBLE, TYPES.ATTRIBUTE);
  public isArrowsVisible: IsArrowsVisibleModel = new IsArrowsVisibleModel(KEYS.IS_ARROWS_VISIBLE, TYPES.ATTRIBUTE);
  public isSlideTextAdded: IsSlideTextVisibleModel = new IsSlideTextVisibleModel(KEYS.IS_SLIDE_TEXT_ADDED, TYPES.ATTRIBUTE);
  public isSlideButtonAdded: IsSlideButtonVisibleModel = new IsSlideButtonVisibleModel(KEYS.IS_SLIDE_BUTTON_ADDED, TYPES.ATTRIBUTE);
  public isSlideshowAutoplayEnabled: IsSlideshowAutoplayEnabledModel = new IsSlideshowAutoplayEnabledModel(KEYS.IS_SLIDESHOW_AUTOPLAY_ENABLED, TYPES.ATTRIBUTE);
  public imageFitInto: ImageFitIntoModel = new ImageFitIntoModel(KEYS.IMAGE_FIT_INTO, TYPES.CLASS);
  public imageEnlargement: ImageEnlargementModel = new ImageEnlargementModel(KEYS.IMAGE_ENLARGEMENT, TYPES.ATTRIBUTE);
  public animationDelay: AnimationDelayModel = new AnimationDelayModel(KEYS.ANIMATION_DELAY, TYPES.ATTRIBUTE);
  public loaderType: LoaderTypeModel = new LoaderTypeModel(KEYS.LOADER_TYPE, TYPES.ATTRIBUTE);
  public customizableLayers: CustomizableLayersModel = new CustomizableLayersModel(KEYS.CUSTOMIZABLE_LAYERS, TYPES.ATTRIBUTE);
  public imageLabelToggle: ImageLabelToggleModel = new ImageLabelToggleModel(KEYS.IMAGE_LABEL_TOGGLE, TYPES.CLASS);
  public imageLabelButton: ImageLabelButtonModel = new ImageLabelButtonModel(KEYS.IMAGE_LABEL_BUTTON, TYPES.ATTRIBUTE);
  public imageLabelPosition: ImageLabelPositionModel = new ImageLabelPositionModel(KEYS.IMAGE_LABEL_STYLES, TYPES.CLASS);
  public imageTransition: ImageTransitionModel = new ImageTransitionModel(KEYS.IMAGE_TRANSITION, TYPES.CLASS);
  public imageTransitionDuration: ImageTransitionDurationModel = new ImageTransitionDurationModel(KEYS.IMAGE_TRANSITION_DURATION, TYPES.STYLE_PROPERTY);
  public slideshowImageTransitionDuration: SlideshowImageTransitionDurationModel = new SlideshowImageTransitionDurationModel(KEYS.SLIDESHOW_IMAGE_TRANSITION_DURATION, TYPES.STYLE_PROPERTY);
  public initialOverlayClickBehavior: InitialOverlayClickBehaviorModel = new InitialOverlayClickBehaviorModel(KEYS.INITIAL_OVERLAY_CLICK_BEHAVIOR, TYPES.ATTRIBUTE);
  public selectLayer: SelectLayerModel = new SelectLayerModel(KEYS.SELECT_LAYER, TYPES.ATTRIBUTE);
  public blockAlignment: BlockAlignmentModel = new BlockAlignmentModel(KEYS.BLOCK_ALIGNMENT, TYPES.CLASS);
  public imageAlignment: ImageAlignmentModel = new ImageAlignmentModel(KEYS.IMAGE_ALIGNMENT, TYPES.CLASS);
  public slideshowHorizontalTextAlignmentModel: SlideshowHorizontalTextAlignmentModel = new SlideshowHorizontalTextAlignmentModel(KEYS.SLIDESHOW_HORIZONTAL_TEXT_ALIGNMENT, TYPES.CLASS);
  public slideshowVerticalTextAlignmentModel: SlideshowVerticalTextAlignmentModel = new SlideshowVerticalTextAlignmentModel(KEYS.SLIDESHOW_VERTICAL_TEXT_ALIGNMENT, TYPES.CLASS);
  public slideshowBorderStyle: SlideshowImageBorderStyleModel = new SlideshowImageBorderStyleModel(KEYS.SLIDESHOW_BORDER_STYLE, TYPES.STYLE_PROPERTY);
  public slideshowBorderWidth: SlideshowImageBorderWidthModel = new SlideshowImageBorderWidthModel(KEYS.SLIDESHOW_BORDER_WIDTH, TYPES.STYLE_PROPERTY);
  public slideshowBorderColor: SlideshowImageBorderColorModel = new SlideshowImageBorderColorModel(KEYS.SLIDESHOW_BORDER_COLOR, TYPES.STYLE_PROPERTY);
  public slideshowBorderRadius: SlideshowImageBorderRadiusModel = new SlideshowImageBorderRadiusModel(KEYS.SLIDESHOW_BORDER_RADIUS, TYPES.STYLE_PROPERTY);
  public slideshowTextBackgroundColor: SlideshowTextBackgroundColorModel = new SlideshowTextBackgroundColorModel(KEYS.SLIDESHOW_TEXT_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public slideshowTextBackgroundTransparency: SlideshowTextBackgroundTransparencyModel = new SlideshowTextBackgroundTransparencyModel(KEYS.SLIDESHOW_TEXT_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public slideshowTransition: SlideshowTransitionModel = new SlideshowTransitionModel(KEYS.SLIDESHOW_TRANSITION, TYPES.ATTRIBUTE);
  public slideshowVideoMuted: IsSlideshowVideoMutedModel = new IsSlideshowVideoMutedModel(KEYS.IS_SLIDESHOW_VIDEO_MUTED, TYPES.ATTRIBUTE);
  public scalableSlideshowTitleFontSize: ScalableSlideshowTitleFontSizeModel = new ScalableSlideshowTitleFontSizeModel(KEYS.SCALABLE_SLIDESHOW_TITLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleLineHeight: ScalableSlideshowTitleLineHeightModel = new ScalableSlideshowTitleLineHeightModel(KEYS.SCALABLE_SLIDESHOW_TITLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleLetterSpacing: ScalableSlideshowTitleLetterSpacingModel = new ScalableSlideshowTitleLetterSpacingModel(KEYS.SCALABLE_SLIDESHOW_TITLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleWordSpacing: ScalableSlideshowTitleWordSpacingModel = new ScalableSlideshowTitleWordSpacingModel(KEYS.SCALABLE_SLIDESHOW_TITLE_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleFontFamily: SlideshowTitleFontFamilyModel = new SlideshowTitleFontFamilyModel(KEYS.SLIDESHOW_TITLE_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleColor: SlideshowTitleColorModel = new SlideshowTitleColorModel(KEYS.SLIDESHOW_TITLE_COLOR, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleFontWeight: SlideshowTitleFontWeightModel = new SlideshowTitleFontWeightModel(KEYS.SLIDESHOW_TITLE_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleTextTransform: SlideshowTitleTextTransformModel = new SlideshowTitleTextTransformModel(KEYS.SLIDESHOW_TITLE_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public scalableSlideshowTitleTextAlignment: SlideshowTitleTextAlignmentModel = new SlideshowTitleTextAlignmentModel(KEYS.SLIDESHOW_TITLE_TEXT_ALIGNMENT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphFontSize: ScalableSlideshowParagraphFontSizeModel = new ScalableSlideshowParagraphFontSizeModel(KEYS.SCALABLE_SLIDESHOW_PARAGRAPH_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphLineHeight: ScalableSlideshowParagraphLineHeightModel = new ScalableSlideshowParagraphLineHeightModel(KEYS.SCALABLE_SLIDESHOW_PARAGRAPH_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphLetterSpacing: ScalableSlideshowParagraphLetterSpacingModel = new ScalableSlideshowParagraphLetterSpacingModel(KEYS.SCALABLE_SLIDESHOW_PARAGRAPH_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphWordSpacing: ScalableSlideshowParagraphWordSpacingModel = new ScalableSlideshowParagraphWordSpacingModel(KEYS.SCALABLE_SLIDESHOW_PARAGRAPH_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphFontFamily: SlideshowParagraphFontFamilyModel = new SlideshowParagraphFontFamilyModel(KEYS.SLIDESHOW_PARAGRAPH_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphColor: SlideshowParagraphColorModel = new SlideshowParagraphColorModel(KEYS.SLIDESHOW_PARAGRAPH_COLOR, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphFontWeight: SlideshowParagraphFontWeightModel = new SlideshowParagraphFontWeightModel(KEYS.SLIDESHOW_PARAGRAPH_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphTextTransform: SlideshowParagraphTextTransformModel = new SlideshowParagraphTextTransformModel(KEYS.SLIDESHOW_PARAGRAPH_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public scalableSlideshowParagraphTextAlignment: SlideshowParagraphTextAlignmentModel = new SlideshowParagraphTextAlignmentModel(KEYS.SLIDESHOW_PARAGRAPH_TEXT_ALIGNMENT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonFontSize: ScalableSlideshowButtonFontSizeModel = new ScalableSlideshowButtonFontSizeModel(KEYS.SCALABLE_SLIDESHOW_BUTTON_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonLineHeight: ScalableSlideshowButtonLineHeightModel = new ScalableSlideshowButtonLineHeightModel(KEYS.SCALABLE_SLIDESHOW_BUTTON_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonLetterSpacing: ScalableSlideshowButtonLetterSpacingModel = new ScalableSlideshowButtonLetterSpacingModel(KEYS.SCALABLE_SLIDESHOW_BUTTON_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonWordSpacing: ScalableSlideshowButtonWordSpacingModel = new ScalableSlideshowButtonWordSpacingModel(KEYS.SCALABLE_SLIDESHOW_BUTTON_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonFontFamily: SlideshowButtonFontFamilyModel = new SlideshowButtonFontFamilyModel(KEYS.SLIDESHOW_BUTTON_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonColor: SlideshowButtonColorModel = new SlideshowButtonColorModel(KEYS.SLIDESHOW_BUTTON_COLOR, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonFontWeight: SlideshowButtonFontWeightModel = new SlideshowButtonFontWeightModel(KEYS.SLIDESHOW_BUTTON_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public scalableSlideshowButtonTextTransform: SlideshowButtonTextTransformModel = new SlideshowButtonTextTransformModel(KEYS.SLIDESHOW_BUTTON_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public buttonBgColor: ButtonBgColorModel = new ButtonBgColorModel(KEYS.BUTTON_BG_COLOR, TYPES.STYLE_PROPERTY);
  public buttonHoverColor: ButtonHoverColorModel = new ButtonHoverColorModel(KEYS.BUTTON_HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public imageCaptionPosition: ImageCaptionPositionModel = new ImageCaptionPositionModel(KEYS.IMAGE_CAPTION_POSITION, TYPES.ATTRIBUTE);
  public position: PositionModel = new PositionModel(KEYS.POSITION, TYPES.ATTRIBUTE);
  public imageCrop: ImageCropModel = new ImageCropModel(KEYS.IMAGE_CROP, TYPES.ATTRIBUTE);
  public href: HrefModel = new HrefModel(KEYS.HREF, TYPES.ATTRIBUTE);
  public linkColor: LinkColorModel = new LinkColorModel(KEYS.LINK_COLOR, TYPES.STYLE_PROPERTY);
  public buttonTextColor: ButtonTextColorModel = new ButtonTextColorModel(KEYS.BUTTON_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public buttonBackgroundColor: ButtonBackgroundColorModel = new ButtonBackgroundColorModel(KEYS.BUTTON_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioId: PortfolioIdModel = new PortfolioIdModel(KEYS.PORTFOLIO_ID, TYPES.ATTRIBUTE);
  public portfolioArrowsBorderRadius: ArrowsBorderRadiusModel = new ArrowsBorderRadiusModel(KEYS.PORTFOLIO_ARROWS_BORDER_RADIUS, TYPES.ATTRIBUTE);
  public portfolioArrowsColor: PortfolioArrowsColorModel = new PortfolioArrowsColorModel(KEYS.PORTFOLIO_ARROWS_COLOR, TYPES.ATTRIBUTE);
  public portfolioArrowsSize: PortfolioArrowsSizeModel = new PortfolioArrowsSizeModel(KEYS.PORTFOLIO_ARROWS_SIZE, TYPES.ATTRIBUTE);
  public portfolioArrowsBackground: ArrowsBackgroundColorModel = new ArrowsBackgroundColorModel(KEYS.PORTFOLIO_ARROWS_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioArrowsBackgroundTransparency: ArrowBackgroundTransparencyModel = new ArrowBackgroundTransparencyModel(KEYS.PORTFOLIO_ARROWS_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementArrowsBorderRadius: PortfolioEnlargementArrowsBorderRadiusModel = new PortfolioEnlargementArrowsBorderRadiusModel(KEYS.PORTFOLIO_ENLARGEMENT_ARROWS_BORDER_RADIUS, TYPES.ATTRIBUTE);
  public portfolioEnlargementArrowsColor: PortfolioEnlargementArrowsColorModel = new PortfolioEnlargementArrowsColorModel(KEYS.PORTFOLIO_ENLARGEMENT_ARROWS_COLOR, TYPES.ATTRIBUTE);
  public portfolioEnlargementArrowsSize: PortfolioEnlargementArrowsSizeModel = new PortfolioEnlargementArrowsSizeModel(KEYS.PORTFOLIO_ENLARGEMENT_ARROWS_SIZE, TYPES.ATTRIBUTE);
  public portfolioEnlargementArrowsBackground: PortfolioEnlargementArrowsBackgroundColorModel = new PortfolioEnlargementArrowsBackgroundColorModel(KEYS.PORTFOLIO_ENLARGEMENT_ARROWS_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementArrowsBackgroundTransparency: PortfolioEnlargementArrowsBackgroundTransparencyModel = new PortfolioEnlargementArrowsBackgroundTransparencyModel(KEYS.PORTFOLIO_ENLARGEMENT_ARROWS_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public portfolioLink: PortfolioLinkModel = new PortfolioLinkModel(KEYS.PORTFOLIO_LINK, TYPES.ATTRIBUTE);
  public portfolioImageTitlePosition: PortfolioImageTitlePositionModel = new PortfolioImageTitlePositionModel(KEYS.PORTFOLIO_IMAGE_TITLE_POSITION, TYPES.ATTRIBUTE);
  public portfolioImageTitleColor: PortfolioImageTitleColorModel = new PortfolioImageTitleColorModel(KEYS.PORTFOLIO_IMAGE_TITLE_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleFontFamily: PortfolioImageTitleFontFamilyModel = new PortfolioImageTitleFontFamilyModel(KEYS.PORTFOLIO_IMAGE_TITLE_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleFontWeight: PortfolioImageTitleFontWeightModel = new PortfolioImageTitleFontWeightModel(KEYS.PORTFOLIO_IMAGE_TITLE_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleFontSize: PortfolioImageTitleFontSizeModel = new PortfolioImageTitleFontSizeModel(KEYS.PORTFOLIO_IMAGE_TITLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleTextTransform: PortfolioImageTitleTextTransformModel = new PortfolioImageTitleTextTransformModel(KEYS.PORTFOLIO_IMAGE_TITLE_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleTextDecoration: PortfolioImageTitleTextDecorationModel = new PortfolioImageTitleTextDecorationModel(KEYS.PORTFOLIO_IMAGE_TITLE_TEXT_DECORATION, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleLineHeight: PortfolioImageTitleLineHeightModel = new PortfolioImageTitleLineHeightModel(KEYS.PORTFOLIO_IMAGE_TITLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleLetterSpacing: PortfolioImageTitleLetterSpacingModel = new PortfolioImageTitleLetterSpacingModel(KEYS.PORTFOLIO_IMAGE_TITLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public portfolioImageTitleWordSpacing: PortfolioImageTitleWordSpacingModel = new PortfolioImageTitleWordSpacingModel(KEYS.PORTFOLIO_IMAGE_TITLE_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementBackground: PortfolioEnlargementBackgroundColorModel = new PortfolioEnlargementBackgroundColorModel(KEYS.PORTFOLIO_ENLARGEMENT_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementBackgroundTransparency: PortfolioEnlargementBackgroundTransparencyModel = new PortfolioEnlargementBackgroundTransparencyModel(KEYS.PORTFOLIO_ENLARGEMENT_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementImageTransition: PortfolioEnlargementImageTransitionModel = new PortfolioEnlargementImageTransitionModel(KEYS.PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION, TYPES.CLASS);
  public portfolioEnlargementImageTransitionDuration: PortfolioEnlargementImageTransitionDurationModel = new PortfolioEnlargementImageTransitionDurationModel(KEYS.PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_DURATION, TYPES.ATTRIBUTE);
  public portfolioEnlargementTitlePosition: PortfolioEnlargementTitlePositionModel = new PortfolioEnlargementTitlePositionModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_POSITION, TYPES.ATTRIBUTE);
  public portfolioEnlargementTitleColor: PortfolioEnlargementTitleColorModel = new PortfolioEnlargementTitleColorModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleFontFamily: PortfolioEnlargementTitleFontFamilyModel = new PortfolioEnlargementTitleFontFamilyModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleFontWeight: PortfolioEnlargementTitleFontWeightModel = new PortfolioEnlargementTitleFontWeightModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleFontSize: PortfolioEnlargementTitleFontSizeModel = new PortfolioEnlargementTitleFontSizeModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleTextTransform: PortfolioEnlargementTitleTextTransformModel = new PortfolioEnlargementTitleTextTransformModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleTextDecoration: PortfolioEnlargementTitleTextDecorationModel = new PortfolioEnlargementTitleTextDecorationModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_TEXT_DECORATION, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleLineHeight: PortfolioEnlargementTitleLineHeightModel = new PortfolioEnlargementTitleLineHeightModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleLetterSpacing: PortfolioEnlargementTitleLetterSpacingModel = new PortfolioEnlargementTitleLetterSpacingModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public portfolioEnlargementTitleWordSpacing: PortfolioEnlargementTitleWordSpacingModel = new PortfolioEnlargementTitleWordSpacingModel(KEYS.PORTFOLIO_ENLARGEMENT_TITLE_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public portfolioZoomBackground: PortfolioZoomBackgroundModel = new PortfolioZoomBackgroundModel(KEYS.ZOOM_MODE_BACKGROUND, TYPES.STYLE_PROPERTY);
  public portfolioZoomIconColor: PortfolioZoomIconColorModel = new PortfolioZoomIconColorModel(KEYS.PORTFOLIO_ZOOM_ICON_COLOR, TYPES.ATTRIBUTE);
  public enlargementIconColor: EnlargementIconColorModel = new EnlargementIconColorModel(KEYS.ENLARGE_ICON_COLOR, TYPES.STYLE_PROPERTY);
  public enlargementIconHoverColor: EnlargementIconHoverColorModel = new EnlargementIconHoverColorModel(KEYS.ENLARGE_ICON_HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public autoplaySlideDuration: AutoplaySlideDurationModel = new AutoplaySlideDurationModel(KEYS.AUTOPLAY_SLIDE_DURATION, TYPES.ATTRIBUTE);
  public portfolioSlideDuration: AutoplaySlideDurationModel = new AutoplaySlideDurationModel(KEYS.PORTFOLIO_SLIDESHOW_SLIDE_DURATION, TYPES.ATTRIBUTE);
  public slideshowSlideDuration: SlideshowSlideDurationModel = new SlideshowSlideDurationModel(KEYS.SLIDESHOW_SLIDE_DURATION, TYPES.ATTRIBUTE);
  public portfolioIntroBackgroundColor: PortfolioIntroBackgroundColorModel = new PortfolioIntroBackgroundColorModel(KEYS.PORTFOLIO_INTRO_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioIntroBackgroundTransparency: PortfolioIntroBackgroundTransparencyModel = new PortfolioIntroBackgroundTransparencyModel(KEYS.PORTFOLIO_INTRO_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public elementBackgroundColor: ElementBackgroundColorModel = new ElementBackgroundColorModel(KEYS.ELEMENT_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public elementBackgroundTransparency: ElementBackgroundTransparencyModel = new ElementBackgroundTransparencyModel(KEYS.ELEMENT_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public portfolioInfoIconBackgroundColor: PortfolioInfoIconBackgroundColorModel = new PortfolioInfoIconBackgroundColorModel(KEYS.PORTFOLIO_INFO_ICON_BACKGROUND, TYPES.STYLE_PROPERTY);
  public portfolioInfoIconColor: PortfolioInfoIconColorModel = new PortfolioInfoIconColorModel(KEYS.PORTFOLIO_INFO_ICON_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioInfoIconBorderColor: PortfolioInfoIconBorderColorModel = new PortfolioInfoIconBorderColorModel(KEYS.PORTFOLIO_INFO_ICON_BORDER_COLOR, TYPES.STYLE_PROPERTY);
  public thumbDrawerIconSize: ThumbDrawerIconSizeModel = new ThumbDrawerIconSizeModel(KEYS.THUMB_DRAWER_ICON_SIZE, TYPES.STYLE_PROPERTY);
  public thumbDrawerIconBackgroundColor: ThumbDrawerIconBackgroundColorModel = new ThumbDrawerIconBackgroundColorModel(KEYS.THUMB_DRAWER_ICON_BACKGROUND, TYPES.STYLE_PROPERTY);
  public thumbDrawerIconColor: ThumbDrawerIconColorModel = new ThumbDrawerIconColorModel(KEYS.THUMB_DRAWER_ICON_COLOR, TYPES.STYLE_PROPERTY);
  public thumbDrawerIconBorderColor: ThumbDrawerIconBorderColorModel = new ThumbDrawerIconBorderColorModel(KEYS.THUMB_DRAWER_ICON_BORDER_COLOR, TYPES.STYLE_PROPERTY);
  public thumbDrawerFontFamily: ThumbDrawerFontFamilyModel = new ThumbDrawerFontFamilyModel(KEYS.THUMB_DRAWER_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public thumbDrawerFontSize: ThumbDrawerFontSizeModel = new ThumbDrawerFontSizeModel(KEYS.THUMB_DRAWER_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public thumbDrawerFontWeight: ThumbDrawerFontWeightModel = new ThumbDrawerFontWeightModel(KEYS.THUMB_DRAWER_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public thumbDrawerTextTransform: ThumbDrawerTextTransformModel = new ThumbDrawerTextTransformModel(KEYS.THUMB_DRAWER_TEXT_TRANSFORM, TYPES.STYLE_PROPERTY);
  public thumbDrawerLetterSpacing: ThumbDrawerLetterSpacingModel = new ThumbDrawerLetterSpacingModel(KEYS.THUMB_DRAWER_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public thumbDrawerWordSpacing: ThumbDrawerWordSpacingModel = new ThumbDrawerWordSpacingModel(KEYS.THUMB_DRAWER_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public thumbDrawerBackground: ThumbDrawerBackgroundColorModel = new ThumbDrawerBackgroundColorModel(KEYS.THUMB_DRAWER_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public thumbDrawerBackgroundTransparency: ThumbDrawerBackgroundTransparencyModel = new ThumbDrawerBackgroundTransparencyModel(KEYS.THUMB_DRAWER_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public thumbDrawerTextColor: ThumbDrawerTextColorModel = new ThumbDrawerTextColorModel(KEYS.THUMB_DRAWER_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public thumbDrawerBorderRadius: ThumbDrawerBorderRadiusModel = new ThumbDrawerBorderRadiusModel(KEYS.THUMB_DRAWER_BORDER_RADIUS, TYPES.STYLE_PROPERTY);
  public thumbDrawerThumbsPosition: ThumbDrawerThumbsPositionModel = new ThumbDrawerThumbsPositionModel(KEYS.THUMB_DRAWER_THUMBS_POSITION, TYPES.STYLE_PROPERTY);
  public thumbDrawerVisibilityBehavior: ThumbDrawerVisibilityBehaviorModel = new ThumbDrawerVisibilityBehaviorModel(KEYS.THUMB_DRAWER_VISIBILITY_BEHAVIOR, TYPES.ATTRIBUTE);
  public portfolioSlideThumbBackgroundColor: PortfolioSlideThumbBackgroundColorModel = new PortfolioSlideThumbBackgroundColorModel(KEYS.PORTFOLIO_SLIDE_THUMB_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbTextColor: PortfolioSlideThumbTextColorModel = new PortfolioSlideThumbTextColorModel(KEYS.PORTFOLIO_SLIDE_THUMB_COLOR, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbFontFamily: PortfolioSlideThumbFontFamilyModel = new PortfolioSlideThumbFontFamilyModel(KEYS.PORTFOLIO_SLIDE_THUMB_FONT_FAMILY, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbFontSize: PortfolioSlideThumbFontSizeModel = new PortfolioSlideThumbFontSizeModel(KEYS.PORTFOLIO_SLIDE_THUMB_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbFontWeight: PortfolioSlideThumbFontWeightModel = new PortfolioSlideThumbFontWeightModel(KEYS.PORTFOLIO_SLIDE_THUMB_FONT_WEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbLineHeight: PortfolioSlideThumbLineHeightModel = new PortfolioSlideThumbLineHeightModel(KEYS.PORTFOLIO_SLIDE_THUMB_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public portfolioSlideThumbLetterSpacing: PortfolioSlideThumbLetterSpacingModel = new PortfolioSlideThumbLetterSpacingModel(KEYS.PORTFOLIO_SLIDE_THUMB_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public overlayColor: OverlayColorModel = new OverlayColorModel(KEYS.OVERLAY_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public overlayBackgroundTransparency: OverlayBackgroundTransparencyModel = new OverlayBackgroundTransparencyModel(KEYS.OVERLAY_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public slideshowImageOverlayBackgroundColor: SlideshowImageOverlayBackgroundColorModel = new SlideshowImageOverlayBackgroundColorModel(KEYS.SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public slideshowImageOverlayBackgroundTransparency: SlideshowImageOverlayTransparencyModel = new SlideshowImageOverlayTransparencyModel(KEYS.SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public imageOverlayTextColor: ImageOverlayTextColorModel = new ImageOverlayTextColorModel(KEYS.IMAGE_HOVER_OVERLAY_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public imageOverlayBackgroundColor: ImageOverlayBackgroundColorModel = new ImageOverlayBackgroundColorModel(KEYS.IMAGE_HOVER_OVERLAY_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public imageOverlayBackgroundTransparency: ImageOverlayBackgroundTransparencyModel = new ImageOverlayBackgroundTransparencyModel(KEYS.IMAGE_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public thumbOverlayBackgroundColor: ThumbOverlayBackgroundColorModel = new ThumbOverlayBackgroundColorModel(KEYS.THUMB_HOVER_OVERLAY_BACKGROUND, TYPES.STYLE_PROPERTY);
  public thumbOverlayBackgroundTransparency: ThumbOverlayBackgroundTransparencyModel = new ThumbOverlayBackgroundTransparencyModel(KEYS.THUMB_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public imageTransparency: ImageTransparencyModel = new ImageTransparencyModel(KEYS.IMAGE_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public spaceBetweenColumns: SpaceBetweenColumnsModel = new SpaceBetweenColumnsModel(KEYS.SPACE_BETWEEN_COLUMNS, TYPES.STYLE_PROPERTY);
  public masonryType: MasonryTypeModel = new MasonryTypeModel(KEYS.MASONRY_TYPE, TYPES.ATTRIBUTE);
  public gridGap: GridGapModel = new GridGapModel(KEYS.GRID_GAP, TYPES.STYLE_PROPERTY);
  public numberOfColumns: NumberOfColumnsModel = new NumberOfColumnsModel(KEYS.NUMBER_OF_COLUMNS, TYPES.STYLE_PROPERTY);
  public titleSpacing: TitleSpacingModel = new TitleSpacingModel(KEYS.TITLE_SPACING, TYPES.STYLE_PROPERTY);
  public arrowsColor: ArrowsColorModel = new ArrowsColorModel(KEYS.SLIDE_ARROWS_COLOR, TYPES.ATTRIBUTE);
  public arrowsSize: ArrowsSizeModel = new ArrowsSizeModel(KEYS.SLIDE_ARROWS_SIZE, TYPES.STYLE_PROPERTY);
  public arrowsBorderRadius: ArrowsBorderRadiusModel = new ArrowsBorderRadiusModel(KEYS.SLIDE_ARROWS_BORDER_RADIUS, TYPES.ATTRIBUTE);
  public arrowBackground: ArrowsBackgroundColorModel = new ArrowsBackgroundColorModel(KEYS.SLIDE_ARROWS_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public arrowBackgroundTransparency: ArrowBackgroundTransparencyModel = new ArrowBackgroundTransparencyModel(KEYS.SLIDE_ARROWS_TRANSPARENCY, TYPES.STYLE_PROPERTY);
  public blockWidthType: BlockWidthTypeModel = new BlockWidthTypeModel(KEYS.BLOCK_WIDTH_TYPE, TYPES.CLASS);
  public videoUrl: VideoUrlModel = new VideoUrlModel(KEYS.VIDEO_URL, TYPES.ATTRIBUTE);
  public videoPoster: VideoPosterModel = new VideoPosterModel(KEYS.VIDEO_POSTER, TYPES.ATTRIBUTE);
  public videoControls: VideoControlsModel = new VideoControlsModel(KEYS.VIDEO_CONTROLS, TYPES.ATTRIBUTE);
  public videoPreload: VideoPreloadModel = new VideoPreloadModel(KEYS.VIDEO_PRELOAD, TYPES.ATTRIBUTE);
  public videoAutoplay: VideoAutoplayModel = new VideoAutoplayModel(KEYS.VIDEO_AUTOPLAY, TYPES.ATTRIBUTE);
  public videoLoop: VideoLoopModel = new VideoLoopModel(KEYS.VIDEO_LOOP, TYPES.ATTRIBUTE);
  public videoMuted: VideoMutedModel = new VideoMutedModel(KEYS.VIDEO_MUTED, TYPES.ATTRIBUTE);
  public videoDownload: VideoDownloadModel = new VideoDownloadModel(KEYS.VIDEO_DOWNLOAD, TYPES.ATTRIBUTE);
  public videoThumbnail: VideoThumbnailModel = new VideoThumbnailModel(KEYS.VIDEO_THUMBNAIL, TYPES.STYLE_PROPERTY);
  public videoEmbed: VideoEmbedModel = new VideoEmbedModel(KEYS.VIDEO_EMBED, TYPES.ATTRIBUTE);
  public videoEmbedControls: VideoEmbedControlsModel = new VideoEmbedControlsModel(KEYS.VIDEO_EMBED_CONTROLS, TYPES.SRC_QUERY_PARAM);
  public videoEmbedAutoplay: VideoEmbedAutoplayModel = new VideoEmbedAutoplayModel(KEYS.VIDEO_EMBED_AUTOPLAY, TYPES.SRC_QUERY_PARAM);
  public videoEmbedLoop: VideoEmbedLoopModel = new VideoEmbedLoopModel(KEYS.VIDEO_EMBED_LOOP, TYPES.SRC_QUERY_PARAM);
  public videoEmbedMuted: VideoEmbedMutedModel = new VideoEmbedMutedModel(KEYS.VIDEO_EMBED_MUTED, TYPES.SRC_QUERY_PARAM);
  public audioUrl: AudioUrlModel = new AudioUrlModel(KEYS.AUDIO_URL, TYPES.ATTRIBUTE);
  public audioAutoplay: AudioAutoplayModel = new AudioAutoplayModel(KEYS.AUDIO_AUTOPLAY, TYPES.ATTRIBUTE);
  public audioLoop: AudioLoopModel = new AudioLoopModel(KEYS.AUDIO_LOOP, TYPES.ATTRIBUTE);
  public audioMuted: AudioMutedModel = new AudioMutedModel(KEYS.AUDIO_MUTED, TYPES.ATTRIBUTE);
  public desktopFontSize: DesktopFontSizeModel = new DesktopFontSizeModel(KEYS.DESKTOP_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public desktopLetterSpacing: DesktopLetterSpacingModel = new DesktopLetterSpacingModel(KEYS.DESKTOP_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public desktopLineHeight: DesktopLineHeightModel = new DesktopLineHeightModel(KEYS.DESKTOP_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public mobileFontSize: MobileFontSizeModel = new MobileFontSizeModel(KEYS.MOBILE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public mobileLetterSpacing: MobileLetterSpacingModel = new MobileLetterSpacingModel(KEYS.MOBILE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public mobileLineHeight: MobileLineHeightModel = new MobileLineHeightModel(KEYS.MOBILE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public enlargementPadding: EnlargementPaddingModel = new EnlargementPaddingModel(KEYS.ENLARGEMENT_PADDING, TYPES.STYLE_PROPERTY);
  public paddingVw: PaddingVwModel = new PaddingVwModel(KEYS.PADDING_VW, TYPES.STYLE_PROPERTY);
  public hamburgerColor: HamburgerColorModel = new HamburgerColorModel(KEYS.HAMBURGER_COLOR, TYPES.STYLE_PROPERTY);
  public mobileAlbumViewSetup: MobileAlbumViewSetupModel = new MobileAlbumViewSetupModel(KEYS.MOBILE_ALBUM_VIEW_SETUP, TYPES.ATTRIBUTE);
  public scalableFontSize: ScalableFontSizeModel = new ScalableFontSizeModel(KEYS.SCALABLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableLineHeight: ScalableLineHeightModel = new ScalableLineHeightModel(KEYS.SCALABLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableLetterSpacing: ScalableLetterSpacingModel = new ScalableLetterSpacingModel(KEYS.SCALABLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableWordSpacing: ScalableWordSpacingModel = new ScalableWordSpacingModel(KEYS.SCALABLE_WORD_SPACING, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.isItemsPaddingCustomizable,
    this.isCaptionEnabled,
    this.isBlockCentered,
    this.isFullscreenIconShown,
    this.isClickToEnlarge,
    this.isSlideshowEnabled,
    this.isSlideshowAutoplay,
    this.isFooterVisibleOnLoad,
    this.isPortfolioTitleAdded,
    this.isThumbDrawerArrowsVisible,
    this.isThumbDrawerAutoscrollEnabled,
    this.isThumbDrawerScrollbarEnabled,
    this.isTitleAndYearVisibleBelowImage,
    this.isPortfolioEnlargementTitleAndYearVisibleBelowImage,
    this.isScalableText,
    this.isExhibitionTitleVisible,
    this.isSlideTrackerVisible,
    this.isArrowsVisible,
    this.isSlideTextAdded,
    this.isSlideButtonAdded,
    this.isSlideshowAutoplayEnabled,
    this.imageFitInto,
    this.imageEnlargement,
    this.animationDelay,
    this.loaderType,
    this.customizableLayers,
    this.initialOverlayClickBehavior,
    this.selectLayer,
    this.blockAlignment,
    this.imageAlignment,
    this.slideshowHorizontalTextAlignmentModel,
    this.slideshowVerticalTextAlignmentModel,
    this.slideshowBorderStyle,
    this.slideshowBorderWidth,
    this.slideshowBorderColor,
    this.slideshowBorderRadius,
    this.slideshowTextBackgroundColor,
    this.slideshowTextBackgroundTransparency,
    this.slideshowTransition,
    this.slideshowVideoMuted,

    this.scalableSlideshowTitleFontSize,
    this.scalableSlideshowTitleLineHeight,
    this.scalableSlideshowTitleLetterSpacing,
    this.scalableSlideshowTitleWordSpacing,
    this.scalableSlideshowTitleFontFamily,
    this.scalableSlideshowTitleColor,
    this.scalableSlideshowTitleFontWeight,
    this.scalableSlideshowTitleTextTransform,
    this.scalableSlideshowTitleTextAlignment,
    this.scalableSlideshowParagraphFontSize,
    this.scalableSlideshowParagraphLineHeight,
    this.scalableSlideshowParagraphLetterSpacing,
    this.scalableSlideshowParagraphWordSpacing,
    this.scalableSlideshowParagraphFontFamily,
    this.scalableSlideshowParagraphColor,
    this.scalableSlideshowParagraphFontWeight,
    this.scalableSlideshowParagraphTextTransform,
    this.scalableSlideshowParagraphTextAlignment,
    this.scalableSlideshowButtonFontSize,
    this.scalableSlideshowButtonLineHeight,
    this.scalableSlideshowButtonLetterSpacing,
    this.scalableSlideshowButtonWordSpacing,
    this.scalableSlideshowButtonFontFamily,
    this.scalableSlideshowButtonColor,
    this.scalableSlideshowButtonFontWeight,
    this.scalableSlideshowButtonTextTransform,
    this.buttonBgColor,
    this.buttonHoverColor,
    this.imageCaptionPosition,
    this.imageLabelToggle,
    this.imageLabelButton,
    this.imageLabelPosition,
    this.imageTransition,
    this.imageTransitionDuration,
    this.slideshowImageTransitionDuration,
    this.href,
    this.position,
    this.imageCrop,
    this.linkColor,
    this.buttonTextColor,
    this.buttonBackgroundColor,
    this.portfolioId,
    this.portfolioArrowsBorderRadius,
    this.portfolioArrowsColor,
    this.portfolioArrowsSize,
    this.portfolioArrowsBackground,
    this.portfolioArrowsBackgroundTransparency,
    this.portfolioEnlargementArrowsBorderRadius,
    this.portfolioEnlargementArrowsColor,
    this.portfolioEnlargementArrowsSize,
    this.portfolioEnlargementArrowsBackground,
    this.portfolioEnlargementArrowsBackgroundTransparency,
    this.portfolioLink,
    this.portfolioImageTitlePosition,
    this.portfolioImageTitleColor,
    this.portfolioImageTitleFontFamily,
    this.portfolioImageTitleFontWeight,
    this.portfolioImageTitleFontSize,
    this.portfolioImageTitleTextTransform,
    this.portfolioImageTitleTextDecoration,
    this.portfolioImageTitleLineHeight,
    this.portfolioImageTitleLetterSpacing,
    this.portfolioImageTitleWordSpacing,
    this.portfolioEnlargementBackground,
    this.portfolioEnlargementBackgroundTransparency,
    this.portfolioEnlargementImageTransition,
    this.portfolioEnlargementImageTransitionDuration,
    this.portfolioEnlargementTitlePosition,
    this.portfolioEnlargementTitleColor,
    this.portfolioEnlargementTitleFontFamily,
    this.portfolioEnlargementTitleFontWeight,
    this.portfolioEnlargementTitleFontSize,
    this.portfolioEnlargementTitleTextTransform,
    this.portfolioEnlargementTitleTextDecoration,
    this.portfolioEnlargementTitleLineHeight,
    this.portfolioEnlargementTitleLetterSpacing,
    this.portfolioEnlargementTitleWordSpacing,
    this.portfolioZoomBackground,
    this.portfolioZoomIconColor,
    this.enlargementIconColor,
    this.enlargementIconHoverColor,
    this.autoplaySlideDuration,
    this.portfolioSlideDuration,
    this.slideshowSlideDuration,
    this.portfolioIntroBackgroundColor,
    this.portfolioIntroBackgroundTransparency,
    this.elementBackgroundColor,
    this.elementBackgroundTransparency,
    this.portfolioInfoIconBackgroundColor,
    this.portfolioInfoIconColor,
    this.portfolioInfoIconBorderColor,
    this.thumbDrawerIconSize,
    this.thumbDrawerIconBackgroundColor,
    this.thumbDrawerIconColor,
    this.thumbDrawerIconBorderColor,
    this.thumbDrawerFontFamily,
    this.thumbDrawerFontSize,
    this.thumbDrawerFontWeight,
    this.thumbDrawerTextTransform,
    this.thumbDrawerLetterSpacing,
    this.thumbDrawerWordSpacing,
    this.thumbDrawerBackground,
    this.thumbDrawerBackgroundTransparency,
    this.thumbDrawerTextColor,
    this.thumbDrawerBorderRadius,
    this.thumbDrawerThumbsPosition,
    this.thumbDrawerVisibilityBehavior,
    this.portfolioSlideThumbBackgroundColor,
    this.portfolioSlideThumbTextColor,
    this.portfolioSlideThumbFontFamily,
    this.portfolioSlideThumbFontSize,
    this.portfolioSlideThumbFontWeight,
    this.portfolioSlideThumbLineHeight,
    this.portfolioSlideThumbLetterSpacing,
    this.overlayColor,
    this.overlayBackgroundTransparency,
    this.slideshowImageOverlayBackgroundColor,
    this.slideshowImageOverlayBackgroundTransparency,
    this.imageOverlayTextColor,
    this.imageOverlayBackgroundColor,
    this.imageOverlayBackgroundTransparency,
    this.thumbOverlayBackgroundColor,
    this.thumbOverlayBackgroundTransparency,
    this.imageTransparency,
    this.spaceBetweenColumns,
    this.masonryType,
    this.gridGap,
    this.numberOfColumns,
    this.titleSpacing,
    this.arrowsColor,
    this.arrowsSize,
    this.arrowsBorderRadius,
    this.arrowBackground,
    this.arrowBackgroundTransparency,
    this.blockWidthType,
    this.videoUrl,
    this.videoPoster,
    this.videoControls,
    this.videoPreload,
    this.videoAutoplay,
    this.videoLoop,
    this.videoMuted,
    this.videoDownload,
    this.videoThumbnail,
    this.videoEmbed,
    this.videoEmbedControls,
    this.videoEmbedAutoplay,
    this.videoEmbedLoop,
    this.videoEmbedMuted,
    this.audioUrl,
    this.audioAutoplay,
    this.audioLoop,
    this.audioMuted,
    this.desktopFontSize,
    this.desktopLetterSpacing,
    this.desktopLineHeight,
    this.mobileFontSize,
    this.mobileLetterSpacing,
    this.mobileLineHeight,
    this.enlargementPadding,
    this.paddingVw,
    this.hamburgerColor,
    this.mobileAlbumViewSetup,
    this.scalableFontSize,
    this.scalableLineHeight,
    this.scalableLetterSpacing,
    this.scalableWordSpacing,
  ];

  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public init({ element, enabledStyleOptions, device }: { element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>, device: string }) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);
    const portfolioEnlargement: HTMLElement = this.getPortfolioEnlargement(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          device,
          innerWrapper,
          block,
          portfolioEnlargement,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) {
      return null;
    }

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }

  private getPortfolioEnlargement(element: HTMLElement): HTMLElement {
    if (!element) {
      return null;
    }

    return element.classList.contains('portfolio-enlargement-wrapper') ? element : <HTMLElement>element.closest('.portfolio-enlargement-wrapper');
  }
}
